import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';

Modal.setAppElement('#root');

function App() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [problemDescription, setProblemDescription] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);

  // Таймер для блокировки повторной отправки
  useEffect(() => {
    let timer;
    if (isSubmitting) {
      setCanSubmit(false);
      timer = setTimeout(() => {
        setCanSubmit(true);
        setIsSubmitting(false);
      }, 60000); // Блокируем повторную отправку на 60 секунд
    }
    return () => clearTimeout(timer);
  }, [isSubmitting]);

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^(\+7|8)\d{10}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePhoneNumber(phoneNumber)) {
      setErrorModalMessage(
        'Пожалуйста, введите корректный номер телефона в формате +7 912 345 67 89 или 8 912 345 67 89 (без пробелов и лишних символов)'
      );
      setErrorModalIsOpen(true);
      return;
    }

    if (!canSubmit) {
      setErrorModalMessage('Вы уже отправили заявку. Пожалуйста, подождите минуту перед повторной отправкой.');
      setErrorModalIsOpen(true);
      return;
    }

    setIsSubmitting(true);

    try {
      await axios.post('https://backend.repair-pc.kraftdev.ru/send-message', {
        phoneNumber,
        problemDescription,
      });
      setModalMessage(
        'Ваше сообщение отправлено.\nМы свяжемся с вами в ближайшее время.'
      );
      setModalIsOpen(true);
    } catch (error) {
      setErrorModalMessage('Произошла ошибка, попробуйте позже.');
      setErrorModalIsOpen(true);
    }

    setPhoneNumber('');
    setProblemDescription('');
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  return (
    <div className="container">
      <Helmet>
        <title>Ремонт ноутбуков и компьютеров в Красноярске. Удалённая компьютерная помощь по всей России</title>
        <meta
          name="description"
          content="Профессиональный ремонт ноутбуков и компьютеров в Красноярске. Удалённая компьютерная помощь по всей России."
        />
      </Helmet>

      <header className="header">
        <h1>Профессиональный ремонт ноутбуков</h1>
        <h1>и компьютеров в Красноярске</h1>
        <h2>Быстрая и качественная удалённая помощь по всей России!</h2>
        <p>Позвоните нам или оставьте заявку, и мы свяжемся с вами!</p>
        <p className="phone-number">
          ☎ <a href="tel:8-913-598-23-17">8-913-598-23-17</a>
        </p>
      </header>

      <main>
        <section className="services">
          <h2>Наши услуги</h2>
          <ul>
            <li>Диагностика неисправностей</li>
            <li>Ремонт ноутбуков и ПК</li>
            <li>Замена комплектующих</li>
            <li>Настройка программного обеспечения</li>
            <li>Удаление вирусов</li>
          </ul>
        </section>

        <section className="why-us">
          <h2>Почему выбирают нас</h2>
          <ul>
            <li>Быстрый и качественный ремонт</li>
            <li>Доступные цены без скрытых платежей</li>
            <li>Гарантия на все виды работ</li>
            <li>Индивидуальный подход к каждому клиенту</li>
            <li>Бесплатная консультация по телефону</li>
          </ul>
        </section>

        <section className="form-section">
          <h2>Оставьте заявку</h2>
          <form className="contact-form" onSubmit={handleSubmit}>
            <label>
              Ваш номер телефона:
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => {
                  // Оставляем только цифры и знак '+'
                  let newValue = e.target.value.replace(/[^+\d]/g, '');

                  // Ограничиваем длину до 12 символов
                  if (newValue.length > 12) {
                    newValue = newValue.slice(0, 12);
                  }

                  setPhoneNumber(newValue);
                }}
                placeholder="Введите номер телефона"
                required
              />


            </label>

            <label>
              Опишите вашу проблему:
              <textarea
                value={problemDescription}
                onChange={(e) => setProblemDescription(e.target.value)}
                placeholder="Кратко опишите неисправность"
              />
            </label>

            <button type="submit" disabled={!canSubmit}>
              Отправить
            </button>
          </form>
        </section>
      </main>

      <footer>
       <p>Кулаков Алексей Владимирович</p>
       <p>Самозанятый специалист</p>
       <p>Красноярск, Конституции СССР, 17</p>
       <p>ИНН: 246607913596</p>
       <p>Телефон: <a href="tel:+79135982317">+7 (913) 598-23-17</a></p>
       <p>Все права защищены © 2024.</p>
      </footer>

      {/* Модальное окно успешной отправки */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Message Modal"
        className="modal"
        overlayClassName="overlay"
        shouldCloseOnOverlayClick={true}
      >
        <h2>Сообщение</h2>
        <p>{modalMessage}</p>
        <button onClick={closeModal}>Закрыть</button>
      </Modal>

      {/* Модальное окно ошибки */}
      <Modal
        isOpen={errorModalIsOpen}
        onRequestClose={closeErrorModal}
        contentLabel="Error Modal"
        className="modal error-modal"
        overlayClassName="overlay"
        shouldCloseOnOverlayClick={true}
      >
        <div className="icon">⚠️</div>
        <h2>Ошибка</h2>
        <p>{errorModalMessage}</p>
        <button onClick={closeErrorModal}>Хорошо</button>
      </Modal>
    </div>
  );
}

export default App;